import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

function generateArray (obj, number, targetKey = 'title') {
  const output = []
  for (let i = 0; i < number; i++) {
    const newObj = { ...obj }
    newObj[targetKey] = `${obj[targetKey]} ${i}`
    output.push(newObj)
  }
  return output
}

const module = {
  state: {
    init: false,
    learnContent: generateArray({ id: 21, category_name: '市場分析', category_id: 10, seo_url: 'chatgptを使って仕事を楽にする方法-20231214.12.26.101702527970.4731gHqEJIGkTabWmGl05s6EwBbQFLLeHGgX0rX9d4m4', content_tags: '人類學', content_bookmarks: 669, content_bought: 5310, content_completed: 1894, content_likes: 22731, content_status: 'PUBLISHED', content_subtitle: 'AI諮詢師解釋了Chat GPT的力量，最大限度地提高了生產力，以及從基礎到應用的簡單解釋課程', content_title: '4H 充分利用 ChatGPT 和應用直覺的基礎知識，使工作效率提高 10 倍', content_total_duration: 333, content_type: 'COURSE', content_views: 154063, content_wishlist: 3822, content_cover: null, label_id: null, label_name: null, locales_id: 4, locale_name: '日文', price_coin_main: 0, price_coin_alt_type: 'PERCENTAGE', price_coin_alt_max_fixed: 0, price_coin_alt_max_percentage: 0, user_profile_pic: null, user_id: 8, user_name: 'Huat Nakiri', user_role_name: '設想家', content_rating_count: 0, content_rating_avg: null, content_comment_count: 0, content_class_enrollment_start: '2023-04-07 02:02:44', content_class_enrollment_end: null, score: 0, content_class_count: 1, content_class_enrollment_count: 0 },
      10,
      'content_title'),
    pressContent: generateArray({ title: '【獨家】為什麼你的網站沒有人看？', desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', cover: 'https://picsum.photos/200/300', seoUrl: 'why-no-one-see-your-website', view: 300, like: 500 },
      10),
    projectContent: generateArray({ title: 'Form Builder AI ', desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', cover: 'https://picsum.photos/200/300', seoUrl: 'form-buidler-ai', target: 50000, current: 30000, deadline: '2020-12-31' },
      10),
    tankContent: generateArray({ title: 'ML service ', desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', cover: 'https://picsum.photos/200/300', seoUrl: 'ML service', hourrate: 500 },
      10),
    masterLandingPageData: null,
    row: null,
    tableList: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async init ({ state }) {
      const res = await ApiUtils.postData({
        data: {
          // learnContent: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn._.content' },
          // pressContent: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'TBD' },
          // projectContent: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'TBD' },
          // tankContent: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'TBD' },
          masterLandingPageData: { api: 'S0801SettingService.getMasterLandingPageData' }
        }
      })
      state.init = true
      for (const key in res) {
        state[key] = res[key].output
      }
    },
    async getEdit({ commit, state, dispatch }, payload) {
      state.row = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.getEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output[0]

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async doEdit({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.doEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async getTableList({ commit, state, dispatch }, payload) {
      state.tableList = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4601StaffPicksService.getTableList',
          }
        ]
      })
      state.tableList = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.tableList)
      })
    },
    async doEdit_featured({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4601StaffPicksService.doEdit_featured',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
