import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    introContent: [],
    contentCounter: [],
    contentTypesIntro: [],
    contentRating: [],
    contentPartnership: [],
    content: [],
    introUsers: [],
    userComment: [],
    row: null,
    tableList: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async init ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: {
          introContent: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.introContent' },
          introUsers: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.introUsers' },
          userComment: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.userComments' },
          content: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn._.content' },
          contentCounter: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.contentCounter' },
          contentTypesIntro: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.contentTypesIntro' },
          contentRating: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.contentRating' },
          contentPartnership: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'learn.landing.contentPartnership' }
        }
      })
      for (const key in res) {
        state[key] = res[key].output
      }
    },
    async getEdit({ commit, state, dispatch }, payload) {
      state.row = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.getEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output[0]

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async doEdit({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.doEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async getContentList({ commit, state, dispatch }, payload) {
      state.tableList = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4601StaffPicksService.getContentList',
            input: payload
          }
        ]
      })
      state.tableList = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.tableList)
      })
    },
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
