import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const statusMap={
  type:{
    // 'INVITE','APPLY'
    INVITE: {
      text: '邀請',
      color: 'grey'
    },
    APPLY: {
      text: '申請',
      color: 'grey'
    }
  },
  status:{
    PENDING: {
      text: '待處理',
      color: 'grey'
    },
    IN_PROGRESS: {
      text: '處理中',
      color: 'grey'
    },
    DEAL: {
      text: '成交',
      color: 'grey'
    },
    ACTIVE: {
      text: '進行中',
      color: 'grey'
    },
    INACTIVE: {
      text: '已結束',
      color: 'grey'
    },
    REJECTED: {
      text: '已拒絕',
      color: 'grey'
    },
    CLOSED: {
      text: '已關閉',
      color: 'grey'
    },
    DELETED: {
      text: '已刪除',
      color: 'grey'
    },
    REMOVED: {
      text: '已被移除',
      color: 'grey'
    }
  }

}

function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}


const PageConst =  {
  FORM_DEF: {
    editDef: [

    ]
  },
  SEARCH_DEF: {
    inputDef: [
    ],
  },
  LIST_DEF: {
    tableDef:[
    ]
  },
  ACTION: {
    // doAdd: { api: 'T2201ServiceApplicationService.customer_doAdd'},
    // getEdit: { api: 'T2201ServiceApplicationService.customer_getEdit'},
    // doEdit: { api: 'T2201ServiceApplicationService.customer_doEdit'},
    // doRead: { api: 'T2201ServiceApplicationService.customer_doRead'},
    // doDelete: { api: 'T2201ServiceApplicationService.customer_doDelete'},
    doBrowse: { api: 'T1101JobPostingApplicationService.collab_customer_doBrowse'},
  },
}

const module = {
  state: {
    PageConst,

    statusMap: getMapArray(statusMap.status),
    typeMap: getMapArray(statusMap.type),

    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:{}
  },

  getters: {

  },

  mutations: {

  },
  actions: {
    async projectPartner_goToChatroom ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [{ api: 'T1101JobPostingApplicationService.projectPartner_goToChatroom', input: payload }]
      }).then((res) => {
        return res[0].output
      })
    },

  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
